import fetchSchedule from 'request/common/fetch-schedule'
import { range } from 'lodash-es'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const startDateDic = {}

export default function Schedule({ userId, duration, campaignId, serviceIds, isModel, isEvent, onClick, refresh, startAt }) {
  const { state, mutate } = useStatic({
    startDate: null,
    loading: false,
    slotDic: {},
    checkDisplayTimeSlots: true,
    timeSlotCount: 0
  })

  useEffect(() => {
    state.startDate = null
    setStartAt(startAt || startDateDic[userId])
  }, [refresh, userId])

  const setStartAt = async startAt => {
    if (state.loading) return
    const currentDiff = getWeekDiff(state.startDate)
    const nextDiff = getWeekDiff(startAt)
    if (currentDiff !== null && currentDiff === nextDiff) return
    if (state.loading) return
    state.loading = true
    let time = dayjs().add(nextDiff || 0, 'week')
    if (!time.isValid()) time = dayjs()
    state.startDate = time
    startDateDic[userId] = time
    await fetchInfo(time)
    state.loading = false
    checkTimeSlotCount()
    mutate()
  }

  const checkTimeSlotCount = () => {
    if (!state.checkDisplayTimeSlots) return
    state.checkDisplayTimeSlots = false
    if (state.timeSlotCount > 0) return
    weekChange(1)
  }

  const getWeekDiff = time => {
    if (!time) return null
    const diff = dayjs(time.startOf('day')).diff(dayjs().startOf('day'), 'week')
    if (diff < 1) return 0
    if (!isEvent) return diff
    if (diff > 1) return 1
    return diff
  }

  const fetchInfo = async time => {
    const endDate = time.add(6, 'day')
    const info = await fetchSchedule({
      userId,
      duration,
      startDate: time,
      campaignId,
      serviceIds,
      isModel,
      endDate
    })
    state.slotDic = formatter(info, onClick)
    state.timeSlotCount = state.slotDic.reduce((carry, next) => carry + next.length, 0)
  }

  const previousStyle = () => {
    const previousAble = state.startDate > dayjs()
    if (previousAble) return 'cursor-pointer'
    return 'opacity-30'
  }

  const nextStyle = () => {
    const nextAble = !isEvent || state.startDate < dayjs().add(6, 'day')
    if (nextAble) return 'cursor-pointer'
    return 'opacity-30'
  }

  const weekChange = async count => {
    setStartAt(state.startDate.add(count, 'week'))
  }

  const startDate = state.startDate || startDateDic[userId] || dayjs()

  const fetchBody = () => range(7).map(shift => {
    const date = startDate.add(shift, 'day')
    const fetchSlots = () => {
      if (state.loading) return []
      return state.slotDic[shift] || []
    }
    return (
      <Column
        key={ date.ymd() }
        date={ date }
        slots={ fetchSlots() }
        onClick={ onClick }
      />
    )
  })

  return (
    <div>
      <div className='flex items-center justify-between px-2'>
        <div className={ previousStyle() }>
          <ArrowBackIcon onClick={ () => weekChange(-1) }/>
        </div>
        <C.DatePicker
          value={ startDate }
          minDate={ dayjs() }
          target={ (
            <div className='font-bold text-sm underline cursor-pointer'>
              { startDate.yearMonth() }
            </div>
          ) }
          onChange={ value => setStartAt(value) }
        />
        <div className={ cn('rotate-180', nextStyle()) }>
          <ArrowBackIcon onClick={ () => weekChange(1) }/>
        </div>
      </div>

      <div className='flex text-center justify-around py-4 min-h-[26rem]'>
        { fetchBody() }
      </div>

      <div className={ cn('p-20', { hidden: !state.loading }) }>
        <C.Loading />
      </div>
    </div>
  )
}

const Column = ({ date, slots, onClick }) => (
  <div>
    <div className='font-bold text-gray-600 select-none'>{ date.format('dd') }</div>
    <div className='py-3 text-xs text-gray-600 select-none'>{ date.format('M/D') }</div>
    <div className='text-[0.7rem] font-medium w-12'>
      { slots.map(({ slot, available, className }) => (
        <div key={ slot }
          className={ cn(className, 'py-[3px]') }
          onClick={ () => available && onClick && onClick(date.atSlot(slot)) }
        >
          { slot.slotToTime() }
        </div>
      ))}
    </div>
  </div>
)

const formatter = (schedule, onClick) => {
  let minSlot = 48
  let maxSlot = 0
  schedule.forEach(s => {
    const { startSlot, endSlot } = s
    if (startSlot < minSlot) minSlot = startSlot
    if (endSlot > maxSlot) maxSlot = endSlot
  })

  if (minSlot >= maxSlot) {
    minSlot = 24
    maxSlot = 38
  }

  return schedule.map(s => {
    const { closedSlots, bookedSlots } = s
    const closedSlotsDic = closedSlots.trueDic()
    const bookedSlotsDic = bookedSlots.trueDic()
    return range(minSlot, maxSlot).reduce((slots, slot) => {
      if (!closedSlotsDic[slot]) {
        const style = () => {
          if (onClick) return 'hover:bg-business hover:text-white hover:rounded-[0.7rem] hover:opacity-90 cursor-pointer'
          return
        }
        slots.push({
          className: cn('text-business select-none', style()),
          available: true,
          slot
        })
        return slots
      }
      if (bookedSlotsDic[slot]) slots.push({
        className: 'text-gray-300 select-none',
        available: false,
        slot
      })
      return slots
    }, [])
  })
}
