const template = `
  query BookingServices($userId:ID!, $isAdmin:Boolean, $showModelServices:Boolean) {
    bookingServices(input: { stylistUserId: $userId, queryType: both, isAdmin: $isAdmin, showModelServices: $showModelServices }) {
      stylistServices {
        id
        service {
          id
          name
          category
        }
        minPrice
        maxPrice
        serviceTime
        description
        hairBrands {
          id
          name
          url
        }
        minPayment
        prepayAmount
      }
      discounts {
        id
        type
        name
        originalMinPrice
        originalMaxPrice
        discountType
        value
        description
        limitDay
        limitTimeSlot
        limitCustomer
        deadline
        isPublish
        campaign {
          id
          name
        }
        serviceList {
          id
          name
          category
        }
        serviceTime
        minPayment
        prepayAmount
      }
    }
  }
`

export default async function services(userId, isAdmin, showModelServices = false) {
  const { bookingServices } = await request(template, { userId, isAdmin, showModelServices })
  return bookingServices
}
