export default function formatter({ stylistServices, discounts, discountLimits, t }) {

  const serviceMap = store.serviceCategories.reduce((dic, category) => {
    dic[category] = { infos: [], minPrice: 999999, maxPrice: -2, priceText: '' }
    return dic
  }, {})

  stylistServices.forEach(stylistService => {
    const { name, category } = stylistService.service
    categoryCheck(stylistService, [category], serviceMap)

    const { id, minPrice, maxPrice, serviceTime } = stylistService
    stylistService.$type = 'stylistService'
    stylistService.$key = `stylistService_${id}`
    stylistService.$title = name
    stylistService.$range = getPriceRangeText(minPrice, maxPrice, t)
    stylistService.$serviceTimeText = getServiceTimeText(serviceTime, t)
  })
  discounts.forEach(discount => {
    const { serviceList, campaign } = discount
    discountPriceResolver(discount)
    const categories = serviceList.map(service => service.category)
    categoryCheck(discount, categories, serviceMap)
    const { id, originalMinPrice, originalMaxPrice, serviceTime } = discount
    const name = serviceList.map(service => service.name).join(' + ')
    discount.$type = 'discount'
    discount.$key = `discount_${id}`
    discount.$title = campaign ? campaign.name : name
    discount.$originalRange = `${ t('common:originalPrice') }: ${ getPriceRangeText(originalMinPrice, originalMaxPrice, t) }`
    discount.$range = getDiscountPrice(discount, t)
    discount.$serviceTimeText = getServiceTimeText(serviceTime, t)
    discount.$remark = getDiscountRemark(discount, discountLimits, t)
  })

  for (const key in serviceMap) {
    const { minPrice } = serviceMap[key]
    if (minPrice === 999999) serviceMap[key].minPrice = -1
  }

  for (const key in serviceMap) {
    const { minPrice, maxPrice, infos } = serviceMap[key]
    serviceMap[key].priceText = getPriceRangeText(minPrice, maxPrice, t)
    serviceMap[key].infos = infos.sortBy(info => [!!info.minPayment, info.$type, -info.$title.length])
  }

  const services = []
  for (const name in serviceMap) {
    const { infos, priceText } = serviceMap[name]
    if (!infos.length) continue
    const i18nName = t(`common:service.category.${name}`)
    services.push({ name, i18nName, priceText })
  }

  return { services, serviceMap }
}

const discountPriceResolver = discount => {
  const { originalMinPrice, originalMaxPrice, discountType, value } = discount
  switch (discountType) {
    case 0:
      discount.minPrice = value
      discount.maxPrice = value
      break
    case 1:
      discount.minPrice = originalMinPrice
      discount.maxPrice = originalMaxPrice
      if (originalMinPrice > 0) discount.minPrice = Math.floor((originalMinPrice * (100 - value)) / 100 + 0.5)
      if (originalMaxPrice > 0) discount.maxPrice = Math.floor((originalMaxPrice * (100 - value)) / 100 + 0.5)
      break
    case 2:
      discount.minPrice = originalMinPrice
      discount.maxPrice = originalMaxPrice
      if (originalMinPrice > 0) discount.minPrice -= value
      if (originalMaxPrice > 0) discount.maxPrice -= value
      break
    default:
  }
}

const categoryCheck = (info, categories, categoryDic) => {
  let isMatch = false
  for (const category of categories) {
    if (!categoryDic[category]) continue
    addInfoToDic(info, categoryDic, category)
    isMatch = true
  }
  if (isMatch) return
  addInfoToDic(info, categoryDic, 'other')
}

const addInfoToDic = (info, dic, key) => {
  const { infos, minPrice, maxPrice } = dic[key]
  infos.push(info)
  if (info.minPrice >= 0 && info.minPrice < minPrice) dic[key].minPrice = info.minPrice
  if (maxPrice != -1 && info.maxPrice > 0 && info.maxPrice > maxPrice) dic[key].maxPrice = info.maxPrice
  if (info.maxPrice < 0) dic[key].maxPrice = -1
}

const getDiscountRemark = (discount, discountLimits, t) => {
  const limit = getDiscountLimit(discount, discountLimits, t)
  const deadline = getDiscountDeadline(discount.deadline, t)
  if (!deadline) return limit
  return `${limit}\n${deadline}`
}

const getDiscountLimit = (discount, discountLimits, t) => {
  const { limitCustomer, limitDay, limitTimeSlot } = discount
  if (limitCustomer === 0) return ''
  const key = discountLimits[limitCustomer]
  const i18nPath = 'common:discount.limit'
  if (limitCustomer === 5) return t(`${i18nPath}.${key}`, { day: limitDay })
  if (limitCustomer !== 6) return t(`${i18nPath}.${key}`, { time: limitTimeSlot.slotToTime() })
  if (limitCustomer === 7) return t(`${i18nPath}.${key}`, { time: limitTimeSlot.slotToTime() })
  return t(`${i18nPath}.${key}`)
}

const getDiscountDeadline = (deadline, t) => {
  if (deadline === 2147483647) return ''
  const date = dayjs(deadline * 1000).format('YYYY/MM/DD')
  return t('common:discount:effectiveUntil', { date })
}