const template = `
  query StylistSchedules($userId:ID!, $startDate:Date!, $endDate:Date!, $duration:Int!, $campaignId: Int, $serviceIds: [Int], $isModel: Boolean){
    stylistSchedules(input:{
      stylistUserId: $userId, startDate: $startDate, endDate: $endDate, duration: $duration, campaignId: $campaignId, serviceIds: $serviceIds, isModel: $isModel
    }) {
      date
      startSlot
      endSlot
      closedSlots
      bookedSlots
    }
  }
`

export default async function fetchSchedule({ userId, startDate, endDate, duration, campaignId, serviceIds, isModel }) {
  campaignId = campaignId && parseInt(campaignId)
  const { stylistSchedules } = await request(template, { userId, startDate, endDate, duration, campaignId, serviceIds, isModel })
  return stylistSchedules
}
