import servicesRequest from 'request/prepay-booking/services'
import serviceFormatter from './formatter'

const dic = {}

const defaultValue = {
  services: [],
  serviceMap: {},
  stylistServices: []
}

export default function useUserService({ userId, force, isAdmin, showModelServices }) {
  // eslint-disable-next-line no-unused-vars
  const { data, mutate } = useSWR('/hooks/userService', uuid)
  const { discountLimits } = useBookingConfig()
  const t = useI18n()

  const fetchData = () => {
    if (!force && dic[userId]) return
    if (!userId) return
    return reload()
  }

  useEffect(fetchData, [userId])

  const reload = async() => {
    const { stylistServices, discounts } = await servicesRequest(userId, isAdmin, showModelServices)

    const { services, serviceMap } = serviceFormatter({
      stylistServices,
      discounts,
      discountLimits,
      t
    })

    dic[userId] = {
      services,
      serviceMap,
      stylistServices
    }

    mutate()
  }

  const result = dic[userId] || defaultValue

  return {
    ...result,
    reload
  }
}
